export function getConfig(p = {}) {

    const { config = {} } = p

    const commonConfig = config.common || {}
    const globalsConfig = config.globals || {}

    const { WAPP, DEV } = globalsConfig

    const common = {
        ...commonConfig,
        lang: 'hu_HU',
        siteName: 'VLM - Vendéglátós munkák',
        description: 'Álláshirdetési portál a turizmus-vendéglátásban dolgozóknak. Add fel szakács, pultos, felszolgáló vagy bármilyen vendéglátós állás hirdetésedet, és találd meg a megfelelő szakembert!',
        graphql: {
            route: (DEV) ? '/graphql' : '/g' + WAPP
        }
    }

    return {
        config: {
            ...config,
            common: common
        }
    }
}
